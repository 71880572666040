import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'
import Combobox from "@github/combobox-nav"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["input", "list", "field", "display"]
  static values = { url: String }
  static debounces = ['submit']

  connect() { useDebounce(this, { wait: 400 }) }
  listTargetConnected() { this.start() }
  
  selectHandler (event) {
    const listItem = event.target.closest('li')
    // set the hidden field value to the user's id
    this.fieldTarget.value = listItem.dataset.userId;

    // Display the selected user
    var fragment = document.createDocumentFragment(); 
    fragment.appendChild(listItem.firstElementChild.cloneNode(true));
    this.displayTarget.appendChild(fragment);

    // Get rid of everything else
    this.stop();
    this.inputTarget.remove();
    this.listTarget.remove();
  }

  start() {
    this.combobox?.destroy()
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
  }
  
  stop() {
    this.combobox?.stop()
  }

  disconnect() {
    this.combobox?.destroy()
  }

  submit() {
    get(this.urlValue, {
      responseKind: "turbo-stream",
      query: {query: this.inputTarget.value}
    })
  }
}
