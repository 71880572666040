import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'
export default class extends Controller {
  static targets = ['lesson', 'user', 'track', 'lessonsForm', 'group', 'groupsForm']

  showAddLesson() {
    this.lessonsFormTarget.classList.remove("hidden");
  }

  showAddGroup() {
    this.groupsFormTarget.classList.remove("hidden");
  }

  showAddInvitation() {
    this.invitationsFormTarget.classList.remove("hidden");
    document.querySelector("[data-autocomplete-target='input']").focus()
  }

  addLesson(event) {
    const url = this.lessonsFormTarget.action + "/add_lesson"
    const lesson_id = parseInt(this.lessonTarget.value);
    const form = this.lessonsFormTarget;
    var fd = new FormData();
    fd.append("new_lesson_id", lesson_id);
    this.lessonTarget.selectedIndex = 0; // reset the select dropdown

    Rails.ajax({
      type: "POST",
      url: url,
      data: fd,
      success: function(){
        form.classList.add("hidden");
      },
      fail: function(){
        alert("Failed to add the lesson")
      }
    })
  }

  addGroup() {
    const url = this.groupsFormTarget.action
    const group_id = parseInt(this.groupTarget.value);
    const form = this.groupsFormTarget;
    var fd = new FormData();
    fd.append("new_group_id", group_id);
    this.groupTarget.selectedIndex = 0; // reset the select dropdown

    Rails.ajax({
      type: "POST",
      url: url,
      data: fd,
      success: function() {
        form.classList.add("hidden");
        document.getElementById('flash').flash.notice('Successfully added group')
      },
      error: function(data) {
        document.getElementById('flash').flash.alert(data.error)
      }
    })
  }

  removeLesson(event) {
    const lesson_id = event.target.dataset.lessonId
    const url = this.lessonsFormTarget.action + "/remove_lesson"
    var fd = new FormData()
    fd.append("lesson_id", lesson_id);

    Rails.ajax({
      type: "POST",
      url: url,
      data: fd,
      fail: function(){
        alert("Failed to remove the lesson")
      }
    })
  }

  removeGroup(event) {
    const group_id = event.target.dataset.groupId
    const url = event.target.dataset.url
    var fd = new FormData()
    fd.append("group_id", group_id);

    Rails.ajax({
      type: "POST",
      url: url,
      data: fd,
      success: function(){
        document.getElementById('flash').flash.notice('Successfully removed group')
      },
      error: function(data) {
        document.getElementById('flash').flash.alert(data.error)
      }
    })
  }

  addInvitation(event) {
    const url = this.invitationsFormTarget.action + "/add_invitation"
    const user_id = parseInt(this.userTarget.value);
    const form = this.invitationsFormTarget;
    var fd = new FormData()
    fd.append("new_user_id", user_id);

    Rails.ajax({
      type: "POST",
      url: url,
      data: fd,
      success: function(){
        form.classList.add("hidden");
        form.querySelector('#hidden-autocomplete-input').value = ''
        form.querySelector('input[type=text]').value = ''
      },
      fail: function(){
        alert("Failed to invite this person")
      }
    })
  }

  removeInvitation(event) {
    const user_id = event.target.dataset.userId
    const url = this.invitationsFormTarget.action + "/remove_invitation"
    var fd = new FormData()
    fd.append("user_id", user_id);

    let removeUser = function () {
      Rails.ajax({
        type: "POST",
        url: url,
        data: fd,
        success: function () {
          // console.log("success")
        },
        fail: function () {
          alert("Failed to remove the person")
        }
      })
    }

    let registered = event.target.dataset.registered.toLowerCase() == "true"
    if (registered) {
      // need to confirm that they really want to delete
      if (confirm("Are you sure? This person has already signed up for the track and will be removed from all track meetings.")) {
        removeUser()
      }
    } else {
      // they aren't registered yet, so just remove them
      removeUser()
    }
  }

  toggleListHidden(event) {
    const unorderedList = event.target.querySelector('ul')
    if (unorderedList.hasAttribute('hidden')) {
      unorderedList.classList.add('hidden')
    } else {
      unorderedList.classList.remove('hidden')
    }
  }

  listLoaded(event) {
    const unorderedList = event.target.querySelector('ul')
    unorderedList.querySelectorAll('li').forEach(item => {
      item.addEventListener('mouseenter', () => { this.hoverListItem(item) })
    })
  }

  hoverListItem(item) {
    item.closest('ul').querySelectorAll('li').forEach(item => {
      item.classList.remove('active')
      item.setAttribute('aria-selected', false)
    })
    item.classList.add('active')
    item.setAttribute('aria-selected', true)
  }

  navigateList(event) {
    if (event.key != 'ArrowUp' && event.key != 'ArrowDown') { return }
    const unorderedList = event.target.closest('div').querySelector('ul')
    unorderedList.querySelectorAll('li').forEach(item => item.classList.remove('active'))
  }
}
