import { Controller } from "@hotwired/stimulus"
import { Sortable } from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      filter: '.ignore-drag', // Don't drag elements matching this class
      handle: '.draggable',
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newDraggableIndex)

    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data
    })
  }
}
