import { Controller } from "@hotwired/stimulus";

// instructions: add tabs where needed like this, with one tab as active and the others as inactive:
{/* <div class="tabs" data-action="click->tabs#selectTab">
  <div class="tab active" id="user-tab">Users</div>
  <div class="tab inactive" id="content-tab">Content</div>
</div> */}
// add sections to your page by doing something similar to the following, you can use other HTMl tags as well:
{/* <section id="user-tab-view"></section>
<section id="content-tab-view"></section> */}

// notice that the ID of each section is the same as the ID of the tab with the addition of "-view" at the end of the ID

export default class extends Controller {
  selectTab(event) {
    const id = event.target.id

    document.querySelectorAll("[id$='-tab-view']").forEach(view => {
      view.classList.add('hidden')
    })
    document.querySelector("[id='" + id + "-view']").classList.remove("hidden")

    document.querySelectorAll('.tabs .tab').forEach(tab => {
      tab.classList.remove('active')
      tab.classList.add('inactive')
    })
    document.querySelector("[id='" + id + "']").classList.add("active")
  }
}
