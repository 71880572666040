import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["companySelect", "rolesContainer"]

  connect() {
    this.disableCompanyAdmin(); // Ensures roles are set correctly on page load
  }

  // Disable the company admin role if no company is selected
  disableCompanyAdmin() {
    const checkbox = this.rolesContainerTarget.querySelector('#user_role_ids_3');

    if (checkbox) {
      const isEnabled = this.companySelectTarget.value !== "";
      checkbox.disabled = !isEnabled; // Disable or enable the checkbox based on company selection
      checkbox.checked = isEnabled ? checkbox.checked : false; // Uncheck the checkbox if company is not selected
      checkbox.parentElement.title = 'A company is required for the Company Admin Role'
    } else {
      console.error('Checkbox with ID "user_role_ids_3" not found.');
    }
  }
}
