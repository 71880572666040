import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
  }
  
  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(event.target.closest('form'), 'submit')
    }, 300)
  }
}
