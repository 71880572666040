import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Set the time zone cookie
    document.cookie = `user_time_zone=${encodeURIComponent(timezone)}; path=/;`;
  }
}
