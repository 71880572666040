import { Controller } from "@hotwired/stimulus";

export default class ClipboardController extends Controller {
  static targets = [ "source" ]

  copy() {
    this.dispatch("copy", { detail: { content: "Meeting link copied to clipboard!" } })
    navigator.clipboard.writeText(this.sourceTarget.textContent)
  }
}
