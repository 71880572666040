import { Controller } from "@hotwired/stimulus";

// Allows to toggle the visibility of a password field when used with the _password_field partial
export default class extends Controller {
  static targets = ["input", "icon"];

  toggleVisibility() {
    const isPassword = this.inputTarget.type === "password";
    this.inputTarget.type = isPassword ? "text" : "password";
    this.iconTarget.textContent = isPassword ? "visibility" : "visibility_off";
  }
}
