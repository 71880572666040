import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "tagsContainer", "hiddenInput"]

  connect() {
    this.emails = this.hiddenInputTargets.map(input => input.value)
    this.checkAndAddDummyTag();
    this.inputTarget.form.addEventListener("submit", this.handleSubmit.bind(this))
  }

  disconnect() {
    this.inputTarget.form.removeEventListener("submit", this.handleSubmit.bind(this))
  }

  // Process emails before the form submits if the input value is not empty
  handleSubmit(event) {
    if (this.inputTarget.value.trim()) {
      event.preventDefault();
      if(this.processEmails(this.inputTarget.value)) {
        return this.inputTarget.form.submit()
      }

      // If processing returns false stop the form submission
      event.stopPropagation();
    }
  }

  checkAndAddDummyTag() {
    if (this.tagsContainerTarget.querySelectorAll(".email-tag:not(.dummy-tag)").length === 0) {
      this.addDummyTag();
    }
  }

  // We use this dummy tag to show users where emails show up
  // NOTE: The dummy tag is used to set the invitee_emails input to empty on the model
  addDummyTag() {
    const dummyTag = document.createElement("span");
    dummyTag.className = "email-tag dummy-tag cursor-not-allowed opacity-50";
    dummyTag.title = "Emails will show here..."
    dummyTag.innerHTML = `
      example@email.com
      <input type="hidden" name="meeting[invitee_emails][]" data-email-tags-target="hiddenInput">
    `;
    this.tagsContainerTarget.appendChild(dummyTag);
  }

  removeDummyTag() {
    const dummyTag = this.tagsContainerTarget.querySelector(".dummy-tag");
    if (dummyTag) {
      dummyTag.remove();
    }
  }

  addEmail(event) {
    // Handle comma, space, tab, and enter key presses
    const keysToWatch = [",", " ", "Tab", "Enter"];
    if (keysToWatch.includes(event.key)) {
      event.preventDefault()
      this.processEmails(this.inputTarget.value)
    }
  }

  pasteEmails(event) {
    // Handle paste event
    event.preventDefault()

    const pasteContent = (event.clipboardData || window.clipboardData).getData('text')
    this.processEmails(pasteContent)
  }

  processEmails(inputValue) {
    const emailList = inputValue.split(/[\s,]+/); // Split on spaces, commas, or both
    let invalidEmails = [];

    emailList.forEach(email => {
      email = email.trim()
      if (email && this.isValidEmail(email)) {
        this.emails.push(email)
        this.addEmailTag(email)
        this.removeDummyTag()
      } else if (email) {
        invalidEmails.push(email)
      }
    })

    if (invalidEmails.length > 0) {
      this.dispatchFlashAlert(`Invalid email(s): ${invalidEmails.join(', ')}`)
      return false
    }

    this.inputTarget.value = ""
    return true
  }

  removeTag(event) {
    const tag = event.target.closest(".email-tag")
    const email = tag.dataset.email

    this.emails = this.emails.filter(e => e !== email)
    tag.remove()
    this.checkAndAddDummyTag();
  }

  isValidEmail(email) {
    const emailRegex = /\S+@\S+\.\S+/
    return emailRegex.test(email)
  }

  addEmailTag(email) {
    const tag = document.createElement("span")
    tag.className = "email-tag"
    tag.dataset.email = email
    tag.innerHTML = `
      ${email}
      <button type="button" class="email-tag-button" data-action="email-tags#removeTag" aria-label="Remove ${email}">&times;</button>
      <input type="hidden" name="meeting[invitee_emails][]" value="${email}" data-email-tags-target="hiddenInput">
    `
    this.tagsContainerTarget.appendChild(tag)
  }

  dispatchFlashAlert(message) {
    const event = new CustomEvent("flash:alert", {
      detail: { content: message },
    })
    this.element.dispatchEvent(event)
  }
}
