import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'


var top6MotivatorsArray;
var steps_count = 0;
var scrollPosition = 0;
var selectedMotivators = []
var top6Motivators = []
var top3Motivators = []
var focusMotivators = []
var selectedFlipCards = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10:0
}
export default class extends Controller {
  static values = {
  }

  static targets = [
     "stage", 
     "flipcard", 
     "back", 
     "step", 
     "progress", 
     "selectedMotivatorsDiv", 
     "top6MotivatorsDiv", 
     "top3MotivatorsDiv", 
     "finishedMotivatorsDiv", 
     "focusMotivatorText", 
     "focusMotivatorDefinition", 
     "focusMotivatorDefinitionDiv", 
     "focusMotivatorWhy",
     "focusMotivatorWhyDiv",
     "authenticity_token",
     "formFocusDriver",
     "formDriversList",
     "formFocusWhat",
     "formFocusWhy"
     ]

  initialize() {
     
  }

  connect() {
    
  }

  disconnect() {
   
  }

  collectionHas(a, b) { 
    for(var i = 0, len = a.length; i < len; i ++) {
     if(a[i] == b) return true;
    }
    return false;
  }

  findParentBySelector(elm, selector) {
    var all = document.querySelectorAll(selector);
    var cur = elm.parentNode;
    
    while(cur && !this.collectionHas(all, cur)) { //keep going up until you find a match
      cur = cur.parentNode; //go up
    }

    return cur; //will return null if not found
  }

  updateStep(direction) {
    if (direction == "next") {
      this.progressTarget.children[steps_count].classList.add("done")
    } else {
      this.progressTarget.children[steps_count - 1].classList.remove("done")
    }
  }

  nextScene(e) {
    let current_scene = this.findParentBySelector(e.target, '.scene')
    var nextSceneDiv = current_scene.nextElementSibling
    var prevSceneDiv = current_scene.previousElementSibling
    current_scene.style.height = 0;
    nextSceneDiv.style.height = '100%';

    switch(e.target.id) {
      case "last_step":
        console.log("last step")
        break;
      default:
        this.updateStep("next")
        steps_count ++;
        e.preventDefault();
    }
  }

  previousScene(e) {
    let current_scene = this.findParentBySelector(e.target, '.scene')
    var nextSceneDiv = current_scene.nextElementSibling;
    var prevSceneDiv = current_scene.previousElementSibling;
    console.log("next", nextSceneDiv)
    console.log("previous", prevSceneDiv)
    this.updateStep("prev");
    steps_count --

    current_scene.style.height = 0;
    prevSceneDiv.style.height = '100%';
    e.preventDefault();
  }

  submitResults(){
    top6MotivatorsArray = JSON.stringify(top6Motivators.join(","));
    this.formFocusDriverTarget.value = focusMotivators[0]
    this.formDriversListTarget.value = top6MotivatorsArray
    this.formFocusWhatTarget.value = this.focusMotivatorDefinitionTarget.value
    this.formFocusWhyTarget.value = this.focusMotivatorWhyTarget.value
  }

  renderFinishedMotivators() {
    var div = this.finishedMotivatorsDivTarget
    div.innerHTML = "";
    top6Motivators.forEach(function(value, index) {
      const flipcardDiv = document.createElement("div")
      flipcardDiv.className = "motivator";
      flipcardDiv.appendChild(document.createTextNode(value));
      div.appendChild(flipcardDiv)
    })
  }

  renderFlipCardSet(set, container){
    container.innerHTML = "";
    set.forEach(function(value, index){
      const flipcardDiv = document.createElement("div")
      flipcardDiv.className = "flipcard";
      const frontDiv = document.createElement("div")
      frontDiv.className = "card-front";
      const backDiv = document.createElement("div")
      backDiv.className = "card-back";
      frontDiv.appendChild(document.createTextNode(value));
      backDiv.appendChild(document.createTextNode(value));
      flipcardDiv.appendChild(frontDiv)
      flipcardDiv.appendChild(backDiv)
      container.appendChild(flipcardDiv)
      flipcardDiv.dataset.action = "click->drivers#flipCardSelected"
    })
  }

  flipCardSelected(e) {
    let flipcards = this.findParentBySelector(e.target, ".flipcards");
    let flipcard = this.findParentBySelector(e.target, ".flipcard");
    let group_count = selectedFlipCards[parseInt(flipcards.dataset.group)];
    let group_limit = parseInt(flipcards.dataset.limit);

    if ((group_count < group_limit ) || flipcard.classList.contains("is_flipped")) {
      flipcard.classList.toggle("is_flipped"); // flip the flipcard
      
    // if it's flipped to the back
      if (flipcard.classList.contains("is_flipped")) {
        // up the number of selected flipcards for the group
        selectedFlipCards[parseInt(flipcards.dataset.group)] ++;
        // decide what to do based on the id of the flipcards div
        switch(flipcards.id) {
          case "demo":
            break;
        
          case "selected-motivators":
            top6Motivators.push(e.target.textContent); // put the selection in the top6 array
            this.renderFlipCardSet(top6Motivators, this.top6MotivatorsDivTarget)
            break;
        
          case "top-six-motivators":
            top3Motivators.push(e.target.textContent)
            this.renderFlipCardSet(top3Motivators, this.top3MotivatorsDivTarget)
            // Anytime we change the top 6, remove the Focus Driver
            focusMotivators.pop();
            selectedFlipCards[10] = 0
            break;
          
          case "top-three-motivators":
            focusMotivators.push(e.target.textContent)
            
            this.focusMotivatorTextTargets.forEach(function(value, index){
              value.textContent = focusMotivators[0]
            })
            this.renderFinishedMotivators()
            break;
          
          default:
            selectedMotivators.push(e.target.textContent);
            this.renderFlipCardSet(selectedMotivators, this.selectedMotivatorsDivTarget)
        }
  
      } else {
      
        selectedFlipCards[parseInt(flipcards.dataset.group)] --;
          switch(flipcards.id) {
            case "selected-motivators":
              top6Motivators.splice(top6Motivators.indexOf(e.target.textContent), 1);
              this.renderFlipCardSet(top6Motivators, this.top6MotivatorsDivTarget);
              selectedFlipCards[9] = 0
              top3Motivators = []
              break;
            case "top-six-motivators":
              top3Motivators.splice(top3Motivators.indexOf(e.target.textContent), 1);
              this.renderFlipCardSet(top3Motivators, this.top3MotivatorsDivTarget);
              focusMotivators.pop();
              selectedFlipCards[10] = 0
              break;
            case "top-three-motivators":
              focusMotivators.splice(focusMotivators.indexOf(e.target.textContent), 1);
              this.focusMotivatorTargets.forEach(function(value, index){
                value.textContent = e.target.textContent;
              })
              this.renderFinishedMotivators()
              break;
            default:
              selectedMotivators.splice(selectedMotivators.indexOf(e.target.textContent), 1);
              this.renderFlipCardSet(selectedMotivators, this.selectedMotivatorsDivTarget);
       }
     }
    } else {
      flipcard.classList.add("shake_it_up");
      setTimeout(function(){
        flipcard.classList.remove("shake_it_up");
      }, 500);
    }

  }

}