import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
export default class extends Controller {

  toggleMoreInfo(event) {
    let element = event.currentTarget.closest('.nested-fields').querySelector('.hidden-info')
    element.hidden = !element.hidden
  }

  flatpickerTargetConnected(container) {
    flatpickr(container, {
      enableTime: true,
      altInput: true,
      altFormat: "F j, Y at h:i K",
      defaultDate: container.value,
      minuteIncrement: 15,
      minDate: "today"
    });
  }

  deleteTargetConnected(container) {
    container.addEventListener("click", (event) => {
      if (container.dataset.id.length > 0) {
        let url = container.dataset.url;
        let token = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "delete",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            authenticity_token: token,
          })
        })
          .then(async response => {
            if (response.status != 200) {
              let body = await response.json()
              throw "Failed to delete the meeting:\n" + body.errors.join("\n")
            } else {
              container.closest('.nested-fields').remove()
            }
          }).catch(err => {
            alert(err);
          });
      } else {
        container.closest('.nested-fields').remove()
        alert("This meeting doesn't exist yet because it hasn't been given a start time yet. If this lesson should not be included in the track then you should remove it from the track.")
      }
    })
  }
}
