import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["popover"]

  connect() {}

  closePopover(){
    this.popoverTarget.classList.add("popover-hidden")
  }

  hotkeys(event){
    if (event.isComposing || event.keyCode === 229) {
      return;
    } else {
      if (event.keyCode == 27) {
        this.closePopover()
      }
    }
  }

  toggle() {
    this.popoverTarget.classList.toggle("popover-hidden")
  }
}
