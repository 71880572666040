import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

var steps_count = 0;
var scrollPosition = 0;
var selectedFlipCards = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10:0
}

const text = '{"all_activities": [],' +
 '"energizing_activities": [],'+
 '"focus_activity": {"name": "","skills": []},'+
 '"focus_skill": {"name": "", "method": "","description": ""}}';
let plan = JSON.parse(text);


export default class extends Controller {
  static values = {
  }

  static targets = [
     "stage", 
     "flipcard", 
     "back", 
     "step", 
     "progress",
     "newActivityInput",
     "newSkillInput",
     "activityListDiv",
     "skillListDiv",
     "allActivitiesDiv",
     "allSkillsDiv",
     "energizingActivitiesDiv",
     "focusActivityDiv",
     "focusSkillTextDiv", //multiple
     "focusActivityTextDiv", //multiple
     "skillMethodButton", //multiple
     "skillDescriptionInput",
     "focusMethodDescription",
     "methodPrompt",
     "formFocusActivity",
     "formFocusSkill",
     "formFocusSkillMethod",
     "formFocusSkillDescription"
     ]

  initialize() {
     
  }

  connect() {
    
  }

  disconnect() {
   
  }

  createNewSkill(e){
    if (e.keyCode == 13 && this.newSkillInputTarget.value != "") {
     // add the item to the page
     const listItemDiv = document.createElement("div")
     listItemDiv.className = "list_item";
     const listItemTextDiv = document.createElement("div")
     listItemTextDiv.className = "text";
     const removeDiv = document.createElement("div")
     removeDiv.className = "remove";
     listItemTextDiv.appendChild(document.createTextNode(this.newSkillInputTarget.value));
     listItemDiv.appendChild(listItemTextDiv)
     listItemDiv.appendChild(removeDiv)
     this.skillListDivTarget.appendChild(listItemDiv)
     removeDiv.dataset.action = "click->skills#removeSkill"
  
     // add to list of skills
     plan.focus_activity.skills.push({"name":this.newSkillInputTarget.value});
     plan.focus_skill.name = ""
     selectedFlipCards[3] = 0

     this.renderSkills()
  
     // reset the field
     this.newSkillInputTarget.value = "";
   }
  }

  createNewActivity(e){
    if (e.keyCode == 13 && this.newActivityInputTarget.value != "") {
     // add the item to the page
     const listItemDiv = document.createElement("div")
     listItemDiv.className = "list_item";
     const listItemTextDiv = document.createElement("div")
     listItemTextDiv.className = "text";
     const removeDiv = document.createElement("div")
     removeDiv.className = "remove";
     listItemTextDiv.appendChild(document.createTextNode(this.newActivityInputTarget.value));
     listItemDiv.appendChild(listItemTextDiv)
     listItemDiv.appendChild(removeDiv)
     this.activityListDivTarget.appendChild(listItemDiv)
     removeDiv.dataset.action = "click->skills#removeActivity"

     // add to list of activities
     plan.all_activities.push({"name":this.newActivityInputTarget.value, "index": plan.all_activities.length});

     // 

     // console.log(plan.all_activities)
     this.renderActivities()

     // reset the field
     this.newActivityInputTarget.value = "";
   }
  }

  renderActivities() {
    this.allActivitiesDivTarget.innerHTML = "";
    const allActivitiesDiv = this.allActivitiesDivTarget
    plan.all_activities.forEach(function(activity, index){
      const flipcardDiv = document.createElement("div")
      flipcardDiv.className = "flipcard";
      const frontDiv = document.createElement("div")
      frontDiv.className = "card-front";
      const backDiv = document.createElement("div")
      backDiv.className = "card-back";
      const textDiv = document.createElement("div")
      textDiv.innerHTML = activity.name;
      let textDivClone = textDiv.cloneNode(true)
      frontDiv.appendChild(textDiv);
      backDiv.appendChild(textDivClone);
      flipcardDiv.appendChild(frontDiv)
      flipcardDiv.appendChild(backDiv)
      allActivitiesDiv.appendChild(flipcardDiv)
      flipcardDiv.dataset.action = "click->skills#flipCardSelected"
    })
  }

  renderEnergizingActivities() {
    this.energizingActivitiesDivTarget.innerHTML = "";
    const energizingDiv = this.energizingActivitiesDivTarget;
    plan.energizing_activities.forEach(function(activity, index){
      const flipcardDiv = document.createElement("div")
      flipcardDiv.className = "flipcard";
      const frontDiv = document.createElement("div")
      frontDiv.className = "card-front";
      const backDiv = document.createElement("div")
      backDiv.className = "card-back";
      const textDiv = document.createElement("div")
      textDiv.innerHTML = activity.name;
      let textDivClone = textDiv.cloneNode(true)
      frontDiv.appendChild(textDiv);
      backDiv.appendChild(textDivClone);
      flipcardDiv.appendChild(frontDiv)
      flipcardDiv.appendChild(backDiv)
      energizingDiv.appendChild(flipcardDiv)
      flipcardDiv.dataset.action = "click->skills#flipCardSelected"
    })
  }

  removeActivity(e) {
    var item = e.target.parentNode
    var itemName = item.children[0].textContent
    plan.all_activities = plan.all_activities.filter(element => element.name != itemName);
    plan.energizing_activities = plan.energizing_activities.filter(element => element.name != itemName);
    
    if(itemName == plan.focus_activity.name) {
      plan.focus_activity.name = ""
    }
    
    selectedFlipCards[1] = 0
    plan.energizing_activities = []
    this.renderActivities()
    this.renderEnergizingActivities()
    // console.log(plan)
    item.remove()
  }

  removeSkill(e) {
    var item = e.target.parentNode
    var itemName = item.children[0].textContent
    plan.focus_activity.skills = plan.focus_activity.skills.filter(element => element.name != itemName);
    plan.focus_skill.name = ""
    selectedFlipCards[3] = 0
    item.remove()
    this.renderSkills()
  }

  renderSkills(){
    this.allSkillsDivTarget.innerHTML = "";
    const listDiv = this.allSkillsDivTarget;
    plan.focus_activity.skills.forEach(function(skill, index){
      const flipcardDiv = document.createElement("div")
      flipcardDiv.className = "flipcard";
      const frontDiv = document.createElement("div")
      frontDiv.className = "card-front";
      const backDiv = document.createElement("div")
      backDiv.className = "card-back";
      const textDiv = document.createElement("div")
      textDiv.innerHTML = skill.name;
      let textDivClone = textDiv.cloneNode(true)
      frontDiv.appendChild(textDiv);
      backDiv.appendChild(textDivClone);
      flipcardDiv.appendChild(frontDiv)
      flipcardDiv.appendChild(backDiv)
      listDiv.appendChild(flipcardDiv)
      flipcardDiv.dataset.action = "click->skills#flipCardSelected"
    })
  }

  renderFocusActivity(){
    this.focusActivityTextDivTargets.forEach(function(div, index) {
      div.textContent = plan.focus_activity.name
    })
    // console.log("add to focus activities", plan.focus_activity)
  }

  renderFocusSkill(){
    this.focusSkillTextDivTargets.forEach(function(div, index) {
      div.textContent = plan.focus_skill.name
    })
    // console.log("add to focus skill", plan.focus_skill.name)
  }

  collectionHas(a, b) { 
    for(var i = 0, len = a.length; i < len; i ++) {
     if(a[i] == b) return true;
    }
    return false;
  }

  updateStep(direction) {
    if (direction == "next") {
      this.progressTarget.children[steps_count].classList.add("done")
    } else {
      this.progressTarget.children[steps_count - 1].classList.remove("done")
    }
  }

  nextScene(e) {
    let current_scene = e.target.closest('.scene');
    var nextSceneDiv = current_scene.nextElementSibling
    var prevSceneDiv = current_scene.previousElementSibling
    current_scene.style.height = 0;
    nextSceneDiv.style.height = '100%';

    switch(e.target.id) {
      case "last_step":
        // console.log("last step")
        break;
      default:
        this.updateStep("next")
        steps_count ++;
        e.preventDefault();
    }
  }

  previousScene(e) {
    let current_scene = e.target.closest('.scene');
    var nextSceneDiv = current_scene.nextElementSibling;
    var prevSceneDiv = current_scene.previousElementSibling;
    // console.log("next", nextSceneDiv)
    // console.log("previous", prevSceneDiv)
    this.updateStep("prev");
    steps_count --

    current_scene.style.height = 0;
    prevSceneDiv.style.height = '100%';
    e.preventDefault();
  }

  submitResults(){
    top6MotivatorsArray = JSON.stringify(top6Motivators.join(","));
    this.formFocusDriverTarget.value = focusMotivators[0]
    this.formDriversListTarget.value = top6MotivatorsArray
    this.formFocusWhatTarget.value = this.focusMotivatorDefinitionTarget.value
    this.formFocusWhyTarget.value = this.focusMotivatorWhyTarget.value
  }


  renderFlipCardSet(set, container){
    container.innerHTML = "";
    set.forEach(function(value, index){
      const flipcardDiv = document.createElement("div")
      flipcardDiv.className = "flipcard";
      const frontDiv = document.createElement("div")
      frontDiv.className = "card-front";
      const backDiv = document.createElement("div")
      backDiv.className = "card-back";
      const textDiv = document.createElement("div")
      textDiv.innerHTML = value;
      let textDivClone = textDiv.cloneNode(true)
      frontDiv.appendChild(textDiv);
      backDiv.appendChild(textDivClone);
      flipcardDiv.appendChild(frontDiv)
      flipcardDiv.appendChild(backDiv)
      container.appendChild(flipcardDiv)
      flipcardDiv.dataset.action = "click->drivers#flipCardSelected"
    })
  }

  flipCardSelected(e) {
    // console.log(plan)
    let flipcards = e.target.closest('.flipcards');
    let flipcard = e.target.closest('.flipcard');
    let group_count = selectedFlipCards[parseInt(flipcards.dataset.group)];
    let group_limit = parseInt(flipcards.dataset.limit);

    if ((group_count < group_limit ) || flipcard.classList.contains("is_flipped")) {
      flipcard.classList.toggle("is_flipped"); // flip the flipcard
      
    // if it's flipped to the back
      if (flipcard.classList.contains("is_flipped")) {
        // up the number of selected cards for the group
        selectedFlipCards[parseInt(flipcards.dataset.group)] ++;
        // decide what to do based on the id of the cards div
        switch(flipcards.id) {
          case "all-activities":
            plan.energizing_activities.push({"name":e.target.textContent});
            this.renderEnergizingActivities()
            selectedFlipCards[2] = 0;
            plan.focus_activity.name = ""
            break;
          case "energizing-activities":
            plan.focus_activity.name = e.target.textContent;
            this.renderFocusActivity()
            break;
          case "all-skills":
            plan.focus_skill.name = e.target.textContent;
            this.renderFocusSkill()
            break
          default:  
        } 
     } else {
       selectedFlipCards[parseInt(flipcards.dataset.group)] --;
       switch(flipcards.id) {
         case "all-activities":
           plan.focus_activity.skills.splice(plan.focus_activity.skills.indexOf({"name": e.target.textContent}))
          plan.energizing_activities = plan.energizing_activities.filter(element => element.name != e.target.textContent);
           selectedFlipCards[2] = 0;
           plan.focus_activity.name = ""
           this.renderEnergizingActivities()
           break;
         case "energizing-activities":
           plan.focus_activity.name = "";
           break;
         case "all-skills":
           plan.focus_skill.name = "";
           this.renderFocusSkill()
         default:
       }
     }
  
     } else {
        flipcard.classList.add("shake_it_up");
        setTimeout(removeShake, 500)
      }
      function removeShake(){
        flipcard.classList.remove("shake_it_up")
      }
    // console.log(plan)
    }

    selectSkillMethod(e){
      // Check if they clicked a child of the div and add a selected class to the right div
      var method;
      if (e.target.classList.contains("method_option")) {
        method = e.target
      } else {
        method = e.target.closest('.method_option');
      }
      document.querySelectorAll(".method_option").forEach(function(option, index){
        option.classList.remove("selected")
      })

      method.classList.add("selected")

      switch(method.children[1].textContent) {
        case "Person":
          this.methodPromptTarget.textContent = "Describe the type of person who can help you build this skill:";
          break;
        case "Project":
          this.methodPromptTarget.textContent = "Describe a project that would help you build this skill:";
          break;
        case "Playbook":
          this.methodPromptTarget.textContent = "Describe a learning resource that would help you build this skill:"
          break;
        default:
      }

      plan.focus_skill.method = method.children[1].textContent
      document.getElementById("skill_method_description").classList.add("visible")
    }

    submitResults(e){
      // console.log(plan);
     // console.log("Activity", plan.focus_activity.name)
     // console.log("Skill", plan.focus_skill.name)
     // console.log("Method", plan.focus_skill.method)
     // console.log("Description", this.skillDescriptionInputTarget.value)
      this.formFocusActivityTarget.value = plan.focus_activity.name
      this.formFocusSkillTarget.value = plan.focus_skill.name
      this.formFocusSkillMethodTarget.value = plan.focus_skill.method
      this.formFocusSkillDescriptionTarget.value = this.skillDescriptionInputTarget.value
    }

}