import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["createEmailId", "allUserIds", "createMeetingId", "deleteAllUsersId", "inviteUsersId", "javascriptSelect", "filtersContainer", "option", "selectedUsersText", "url", "form"]

  connect() {
  }

  search(event) {
    this.updateCount(0);
    if (event.target.matches('select')) {
      // DO NOT remove this next line. This is required to prevent a bug where clicking on a button like "Email Users"
      // takes you to that page, then clicking the back button in the browser will reset this field, but
      // the browser will still show the originally selected group, but then clicking on the "Email Users" button
      // again will have ALL users selected, not just the selected ones since the filter dropdown will have reset to all.
      // This took hours to chase down, and removing it will cause subtle errors in the returned data that probably won't be caught by the user.
      event.target.options[event.target.options.selectedIndex].setAttribute('selected', 'selected');
    }
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(event.target.closest('form'), 'submit')
    }, 300)
  }

  selectAll(event) {
    // find out if we are selected or not
    const all_selected = event.target.checked;

    // adjust the visible checkmarks to match the setting
    const table = event.target.closest('table')
    Array.from(table.getElementsByTagName('input')).forEach(input => {
      input.checked = all_selected;
      // input.checked = this.selectAllUsersTarget.checked
    })

    // make sure the form knows that we are in select all mode or not
    document.getElementById('select_all').value = all_selected;
    if (all_selected) {
      // we have checked all, so let's make sure we have all users pulled in (since this will be blank on first page load for performance reasons)
      Rails.fire(document.getElementById('search_form').getElementsByTagName('form')[0], 'submit')
    } else {
      // we want none of them, so reset the value
      this.allUserIdsTarget.dataset.ids = '';
    }

    // update the button counts
    const ids = this.getAllIds()
    const count = ids.length
    this.updateCount(count);
  }

  selectUser(event) {
    // if we are selecting users then it isn't `select all`.
    // We need to do this since we can't really allow selecting or deselecting and then going to the next paginated page
    // to select another one, which the users will think they can do. We are either selecting all, or we aren't.
    document.getElementById('select-all-users').checked = false;
    document.getElementById('select_all').value = false;

    // find the table so that we can find which items are selected
    const table = event.target.closest('table')

    // return the IDs of the selected users
    const selectedIds = Array.from(table.getElementsByTagName('input')).filter(input => { return input.checked }).map(input => input.dataset.id)

    // make sure our list of selected ID's is updated
    this.allUserIdsTarget.dataset.ids = selectedIds.join(",");

    // update the button counts
    const count = selectedIds.length;
    this.updateCount(count);
  }

  updateCount(count) {
    const selectedUsers = this.selectedUsersTextTarget;
    selectedUsers.innerText = selectedUsers.innerText.replace(/\d+/, count);

  }

  getAllIds() {
    const ids = this.allUserIdsTarget.dataset.ids;
    return ids.length > 0 ? ids.split(",") : []; // this is necessary since doing a split(",") on "" returns one element
  }

  inviteToTrack() {
    const inv = this.inviteUsersIdTarget
    inv.href = this.originalUrl(inv.href) + this.requestParameters()
  }

  removeInviteUser(event) {
    event.target.closest("tr").remove();
  }

  deleteAllUsers() {
    const del = this.deleteAllUsersIdTarget
    del.href = this.originalUrl(del.href) + this.requestParameters()
  }

  updateDownloadUrl() {
    const downloadCsv = document.getElementById('download_csv')
    downloadCsv.href = this.originalUrl(downloadCsv.href) + this.requestParameters()
  }

  updateEmailUrl() {
    const emailLink = this.createEmailIdTarget
    emailLink.href = this.originalUrl(emailLink.href) + this.requestParameters()
  }

  updateMeetingsUrl() {
    const meetingLink = this.createMeetingIdTarget
    meetingLink.href = this.originalUrl(meetingLink.href) + this.requestParameters()
  }

  updateNotificationUrl() {
    const notificationLink = document.getElementById('notify-campfire')
    notificationLink.href = this.originalUrl(notificationLink.href) + this.requestParameters()
  }

  originalUrl(href) {
    return href.split('?')[0]
  }

  requestParameters() {
    const searchValue = document.getElementById('search').value
    const companyValues = Array.from(document.querySelectorAll('#company_id option:checked')).map(o => o.value)
    const roleValues = Array.from(document.querySelectorAll('#role_id option:checked')).map(o => o.value)
    const registeredTrackValues = Array.from(document.querySelectorAll('#registered_track_id option:checked')).map(o => o.value)
    const invitedTrackValues = Array.from(document.querySelectorAll('#invited_track_id option:checked')).map(o => o.value)
    const companyIdParams = companyValues.map(id => "&company_id[]=" + encodeURIComponent(id))
    const roleIdParams = roleValues.map(id => "&role_id[]=" + encodeURIComponent(id))
    const registeredTrackIdParams = registeredTrackValues.map(id => "&registered_track_id[]=" + encodeURIComponent(id))
    const invitedTrackIdParams = invitedTrackValues.map(id => "&invited_track_id[]=" + encodeURIComponent(id))
    const notCompanyParams = "&not_company=" + document.getElementById('not_company')?.value
    const notRoleParams = "&not_company=" + document.getElementById('not_role')?.value
    const notRegisteredForParams = "&not_registered_for=" + document.getElementById('not_registered_for')?.value
    const notInvitedToParams = "&not_invited_to=" + document.getElementById('not_invited_to')?.value
    const freeaccountParams = "&free_account=" + document.getElementById('free_account')?.value
    const userIds = this.allUserIdsTarget.dataset.ids;
    const all_selected = document.getElementById('select-all-users').checked;

    return "?search=" + encodeURIComponent(searchValue)
      + roleIdParams.join('')
      + registeredTrackIdParams.join('')
      + invitedTrackIdParams.join('')
      + companyIdParams.join('')
      + notCompanyParams
      + notRoleParams
      + notRegisteredForParams
      + notInvitedToParams
      + freeaccountParams
      + (all_selected ? '&user_ids=all' : '&user_ids=' + userIds);
  }


  // these next three functions are all for the dropdown filters
  addFilter(event) {
    // grab target and get the proper filter ID based off the target
    let elementId = event.target.id.replace("-option", "");
    let filterElement = document.getElementById(elementId);

    if (elementId == 'clearfilters') {
      this.clearAllFilters()
    }
    else {
      // remove it from my 'filter-storage'
      filterElement.remove();
      filterElement.classList.remove("not-added-filter");

      // add it to the filters container
      this.filtersContainerTarget.innerHTML += filterElement.outerHTML;

      // remove the option from the dropdown
      event.target.classList.add("added-filter-option");
    }
    if (elementId == 'freeaccount') {
      let freeaccountFilter = document.getElementById("free_account");
      freeaccountFilter.value = true
      this.search(event)
    }
  }

  clearFilters(select) {
    let selectedOption = select.selectedIndex
    select.options[selectedOption].removeAttribute('selected', 'selected')
  }

  removeFilter(event) {
    // grab elements
    let filterElement = event.target.parentElement;
    let filtersStorage = document.getElementById("filters-storage");
    let dropdownElement = document.getElementById(`${filterElement.id}-option`);
    let form = document.getElementById("filters-container").closest('form');
    let selectArr = event.target.parentElement.querySelectorAll("select");
    let freeaccountFilter = document.getElementById("free_account");

    freeaccountFilter.value = false
    // clear the filter
    if (event.target.parentElement.id == 'deleted') {
      event.target.parentElement.querySelector("input[type=checkbox]").checked = false
    }
    else {
      selectArr.forEach(this.clearFilters);
    }
    // submit the form
    Rails.fire(form, 'submit');

    // remove the filter from container and add option back to dropdown
    filterElement.remove();
    filterElement.classList.add("not-added-filter")
    dropdownElement.classList.remove("added-filter-option");

    // add element back to storage
    filtersStorage.innerHTML += filterElement.outerHTML;
  }

  clearAllFilters() {
    // grab elements
    let container = document.getElementById("filters-container")
    let selectsArr = container.querySelectorAll("select");
    let form = container.closest('form');
    let filtersArr = container.querySelectorAll(".filter")
    let filtersStorage = document.getElementById("filters-storage");
    let deletedFilter = document.getElementById("deleted");
    let freeaccountFilter = document.getElementById("free_account");

    // clear the filters
    selectsArr.forEach(this.clearFilters);

    // set the boolean calumn back to false
    freeaccountFilter.value = false

    // clear the 'deleted' filter
    deletedFilter.querySelector("input[type=checkbox]").checked = false;
    // submit the form
    Rails.fire(form, 'submit');

    filtersArr.forEach(removeFilters);
    function removeFilters(filter) {
      if (!filter.classList.contains("not-added-filter")) {
        // grab dropdown element
        let dropdownElement = document.getElementById(`${filter.id}-option`);

        // remove the filter from container and add option back to dropdown
        filter.remove();
        dropdownElement.classList.remove("added-filter-option");

        // add element back to storage
        filtersStorage.innerHTML += filter.outerHTML;
      }
      else {
        return;
      }
    }
  }

  hidePopup(event) {
    const popupElement = document.getElementById("new-user")
    const openPopup = document.getElementById("open-popup")

    if (popupElement === event.target || popupElement.contains(event.target) || event.target == openPopup) return;
    popupElement.style.display = 'none'
  }

  toggleNewUserPopup() {
    const newUserElement = document.getElementById("new-user")
    if (newUserElement.style.display !== "block") {
      newUserElement.style.display = 'block'
    }
    else {
      newUserElement.style.display = 'none'
    }
  }

  downloadTemplate() {
    Rails.ajax({
      type: "GET",
      url: this.urlTarget.dataset.url,
      success: function (data) {
        // done this way so it supports safari
        const link = document.getElementById("link");
        link.href = "data:application/octet-stream," + encodeURIComponent(data);
        link.download = "upload-user-template.csv";
        link.target = "_blank";
        link.click()
      }
    })
  }

  submitUsers() {
    console.log('submitting?')
    Turbo.navigator.submitForm(this.formTarget)
    document.getElementById("new-user").style.display = 'none'
  }
}
