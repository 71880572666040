import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'addLessonButton',
    'lessonSelect',
  ]
  
  connect() {}

  removeLesson(event) {
    event.preventDefault();
    event.currentTarget.closest('fieldset').remove();
  }

  addLesson() {
    this.addLessonButtonTarget.click()
    this.lessonSelectTarget.selectedIndex = 0
  }
}
