import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['range', 'select']

  connect() {
    this.onSelection()
  }

  onSelection(event) {
    if(this.selectTarget.value == "Stars") {
      this.rangeTarget.style.display = "flex"; 
    } else {
      this.rangeTarget.style.display = "none"; 
    }
    
  }


}
