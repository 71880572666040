import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'title', 'summary', 'url', 'image', 'content_type', 
    'display_title', 'display_summary', 'display_url','display_image', 'display_content_type', 'lesson', 'display_lesson']


  connect() {
    this.update_title()
    this.update_summary()
    this.update_url()
    this.update_image()
    this.update_lesson()
    this.update_content_type()
  }

  update_title(){
    if (this.titleTarget.value != "") {
      this.display_titleTarget.innerHTML = this.titleTarget.value 
    } 
  }

  update_summary(){
    if (this.summaryTarget.value != "") {
      this.display_summaryTarget.innerHTML = this.summaryTarget.value
    }
  }

  update_url(){
    if (this.urlTarget.value != "") {
      this.display_urlTarget.href = this.urlTarget.value
    }
  }

  update_image(){
    if (this.imageTarget.value != "") {
      this.display_imageTarget.style.backgroundImage = "url("+this.imageTarget.value+")";
    }
  }

  update_lesson(){
    if (this.lessonTarget.value != "") {
      this.display_lessonTarget.innerHTML = "From " + this.lessonTarget.options[this.lessonTarget.selectedIndex].text;
    }
  }

  update_content_type(){
    if (this.content_typeTarget.value != "") {
      this.display_content_typeTarget.innerHTML = this.content_typeTarget.value
    }
  }
}