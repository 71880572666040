import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["notification", "parent"]

  connect() {
    this.element[this.identifier] = this
    this.element.addEventListener('flash:alert', this.alertEvent.bind(this))
    this.element.addEventListener('flash:notice', this.noticeEvent.bind(this))
  }

  notificationTargetConnected() {
    this.onScroll()
    this.startCloseTimeout()
  }

  onScroll() {
    if (!this.hasNotificationTarget) return

    const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

    this.notificationTarget.style.top = scrollTop + 'px'
  }

  startCloseTimeout() {
    this.timeout = setTimeout(() => {
      this.close()
    }, 5000)
  }

  alert(htmlMessage) {
    this.appendMessage(htmlMessage, 'alert')
  }

  notice(htmlMessage) {
    this.appendMessage(htmlMessage, 'notice')
  }

  // This is used when dispatch events are triggered by other controllers
  noticeEvent({ detail: { content } }) {
    this.notice(content)
  }

  alertEvent({ detail: { content } }) {
    this.alert(content)
  }

  appendMessage(htmlMessage, type) {
    const parentDiv = document.createElement("div")
    parentDiv.setAttribute('class', 'flash flash--' + type)
    parentDiv.setAttribute("data-flash-target", "notification")
    parentDiv.setAttribute("data-action", "scroll@window->flash#onScroll")

    const messageDiv = document.createElement("div")
    messageDiv.innerHTML = htmlMessage

    const closeButton = document.createElement("button")
    closeButton.setAttribute("type", "button")
    closeButton.setAttribute("class", "flash__closeBtn")
    closeButton.setAttribute("data-action", "flash#close")

    const closeIcon = document.createElement("i")
    closeIcon.setAttribute("class", "close-icon")

    closeButton.appendChild(closeIcon)
    parentDiv.appendChild(messageDiv)
    parentDiv.appendChild(closeButton)

    document.getElementById('flash').appendChild(parentDiv)
  }

  close() {
    this.parentTarget.innerHTML = ''
  }
}
