import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["flatpicker", "capacity", "baseCapacity", "meeting", "updateFacilitatorDropdown", "requestGuide"]

  connect() { }

  flatpickerTargetConnected(container) {
    flatpickr(container, {
      enableTime: true,
      dateFormat: "Y-m-d h:i K",
      altInput: true,
      altFormat: "F j, Y at h:i K",
      minDate: "today",
      time_24hr: false
    });
  }

  requestGuideTargetConnected() {
    this.checkRequestGuide()
  }

  updateGroupSelections(event) {
    const id = event.target.value
    const meetingId = this.meetingTarget.dataset.id

    Rails.ajax({
      type: "GET",
      url: "/lessons/" + id + "/meeting_lesson_groups?meeting_id=" + meetingId
    })
  }

  checkRequestGuide() {
    this.checkMeetingTime()

    const facilitatorDropdown = document.getElementById('meeting_facilitator_id')
    // disable company guide dropdown if requesting campfire guide
    if (this.requestGuideTarget.checked) {
      facilitatorDropdown.selectedIndex = 0
      facilitatorDropdown.disabled = true
      facilitatorDropdown.title = "Cannot choose facilitator when requesting a guide."
    } else {
      facilitatorDropdown.disabled = false
      facilitatorDropdown.title = 'Choose a facilitator'
    }
  }

  checkMeetingTime() {
    const meetingTime = new Date(this.flatpickerTarget.value)
    meetingTime.setHours(0,0,0,0) // compare only dates

    var twoWeeksTime = new Date()
    twoWeeksTime.setHours(0,0,0,0)
    twoWeeksTime.setDate(twoWeeksTime.getDate() + 14)

    // disable request campfire guide checkbox, enable company guide dropdown
    if (meetingTime <= twoWeeksTime) {
      document.getElementById('request-guide').classList.add('disabled')
      this.requestGuideTarget.disabled = true
      this.requestGuideTarget.checked = false

      const facilitatorDropdown = document.getElementById('meeting_facilitator_id')
      facilitatorDropdown.disabled = false
    } else {
      document.getElementById('request-guide').classList.remove('disabled')
      this.requestGuideTarget.disabled = false
    }
  }
}
