import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-previews"
export default class extends Controller {
  static targets = ["input", "preview", "previewFilename"]

  connect() {}

  preview() {
    let input = this.inputTarget
    let preview = this.previewTarget
    let fileName = this.previewFilenameTarget
    let file = input.files[0]
    let reader = new FileReader()

    reader.onloadend = function() { 
      preview.src = reader.result
      fileName.innerHTML = reader.fileName
    }

    if (file) {
      reader.fileName = file.name
      reader.readAsDataURL(file)
    } else {
      preview.src = ""
    }
  }
}
