import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  svgUpgradeTargetConnected(container) {
    var img = document.createElement("img");
    img.src = container.dataset.hq;
    img.onload = function () {
      container.classList.add("hidden")
      img.classList.remove("hidden")
      container.remove()
    }
    img.classList.add("hidden");
    container.parentNode.insertBefore(img, container.nextSibling);
  }

  svgUpgradeBackgroundDivTargetConnected(container) {
    var img = document.createElement("img");
    img.src = container.dataset.hq;
    img.onload = function () {
      container.style.backgroundImage = 'url(' + container.dataset.hq + ')';
      img.remove()
    }
    img.classList.add("hidden");
    container.parentNode.insertBefore(img, container.nextSibling);
  }

}
