import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

let flatpickrInstance

export default class extends Controller {
  static targets = ["flatpicker"]

  connect() {}

  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(event.target.closest('form'), 'submit')
    }, 500)
  }

  flatpickerTargetConnected(container) {
    flatpickrInstance = flatpickr(container, {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "F j, Y",
      defaultDate: container.value,
    });
  }

  editResponse(event) {
    const form = event.target.closest('.journal_entry').querySelector('.hidden.response-form')
    form.classList.remove('hidden')
    event.target.classList.add('hidden')
  }

  submitDate(event) {
    let prom = new Promise((resolve) => {
      Rails.fire(event.target.closest('form'), 'submit')
      resolve()
    })
    prom.then(() => {
      flatpickrInstance.close()
    })
  }

  closeResponseForm(event) {
    event.preventDefault()

    const form = event.target.closest('.journal_entry').querySelector('.response-form')
    form.classList.add('hidden')
    event.target.closest('.journal_entry').querySelector('.hidden.response').classList.remove('hidden')
  }
}
