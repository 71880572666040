import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "trigger", "javascriptSelect"]

  connect() {

  }

  toggleVisibility() {
    this.containerTarget.classList.toggle("hidden");
  }

  toggleSelectStyling() {
    this.javascriptSelectTarget.classList.toggle("drop-arrow")
    this.javascriptSelectTarget.classList.toggle("exit-out")
  }

  closeElement(event) {
    if (this.element === event.containerTarget || this.element.contains(event.target)) return;

    this.containerTarget.classList.add("hidden");
    this.javascriptSelectTarget.classList.remove("exit-out")
    this.javascriptSelectTarget.classList.add("drop-arrow")
  }

  // following two functions are the JS for the mobile nav
  openMenu() {
    if (this.containerTarget) {
      this.containerTarget.classList.toggle("hidden");
    }
    if (this.selectTarget) {
      this.selectTarget.classList.toggle("change");
    }
  }

  closeMenu(event) {
    if (this.element === event.containerTarget || this.element.contains(event.target)) return;
    if (this.containerTarget) {
      this.containerTarget.classList.add("hidden");
    }
    if (this.selectTarget) {
      this.selectTarget.classList.remove("change");
    }
  }

}
