import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["newRecord", "template", "addButton", "capacity", "company"]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_association(event) {
    event.preventDefault()
    const listActionEl = event.target.closest('.list-with-actions')
    const templateTarget = this.templateTargets.find(target => listActionEl.contains(target)) // multiple templates could exist on one page
    const newRecordTarget = this.newRecordTargets.find(target => listActionEl.contains(target)) // multiple newRecords could exist on one page
    const content = templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()) // this is the default functionality of what rails does when adding a new nested object
    newRecordTarget.insertAdjacentHTML('afterbegin', content)
  }

  get_company_users(event) {
    const companyID = this.companyTarget.value
    const url = this.companyTarget.dataset.url

    Rails.ajax({
      type: "GET",
      url: url + "?company_id=" + companyID,
    })
  }

  remove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest("." + this.wrapperClass)
    // Remove new records from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      const destroyField = wrapper.querySelector("input[name*='_destroy']")
      if (!!destroyField !== false) {
        destroyField.value = 1
        wrapper.style.display = 'none'
      } else {
        wrapper.remove()
      }
    }
  }

  showButtonInformation() {
    document.getElementById('add-button-info').classList.remove('hidden')
  }

  hideButtonInformation() {
    document.getElementById('add-button-info').classList.add('hidden')
  }
}
