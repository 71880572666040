import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["lesson"]

  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(event.target.closest('form'), 'submit')
    }, 300)
  }

  updateLessonGroups(event) {
    const companyId = event.target.value
    const lessonId = this.lessonTarget.dataset.id
  }
}
