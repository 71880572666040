import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  handleWidthChange(e) {
    const flexRow = document.getElementById('catalog-flex-row');
    if (e.matches) {
      // while screen-width < 650px, move the dropdown filters into the modal
      document.getElementById('catalog-filters-popover').appendChild(flexRow);
    }
    else {
      // while screen-width > 650px, remove the filters from the modal (and make sure the modal is closed)
      const popoverContainer = document.getElementById('catalog-flex-row-outer-container')
      popoverContainer.appendChild(flexRow);
      popoverContainer.querySelector("button.close").click();
    }
  }

  connect() {
    this.screenWidth = window.matchMedia('(max-width: 650px)');
    this.screenWidth.addEventListener("change", this.handleWidthChange)
  }

  disconnect() {
    this.screenWidth.removeEventListener("change", this.handleWidthChange);
  }

  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(event.target.closest('form'), 'submit')
    }, 300)
  }

  scrollTo(event) {
    document.getElementById(event.target.closest('.card').getAttribute("data-reference")).scrollIntoView({behavior: "smooth"})
  }

  toggleAllTopics(event) {
    const buttonWording = ['View all Topics', 'Hide all Topics']
    event.target.innerHTML = buttonWording.at(buttonWording.indexOf(event.target.innerHTML) - 1)
    document.getElementById('all-topics').classList.toggle('hidden')
  }

  toggleTopicModal() {
    const topicModal = document.getElementById('request-topic-modal')

    if (topicModal.classList.contains('hidden')) {
      topicModal.classList.remove('hidden')
    } else {
      topicModal.classList.add('hidden')
    }
  }

  submitForm(event) {
    event.preventDefault()
    const form = event.target.closest('form')
    Rails.fire(form, 'submit')
    this.toggleTopicModal()
    form.querySelector('textarea').value = ''
  }

  updateSubmitButton(event) {
    const form = event.target.closest('form')
    const submitButton = form.querySelector('input[type=submit]')

    if (form.querySelector('textarea').value.length > 0) {
      submitButton.classList.remove('disabled')
      submitButton.disabled = false
    } else {
      submitButton.classList.add('disabled')
      submitButton.disabled = true
    }
  }
}
