import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {

  submitForm(event) {
    event.preventDefault()
    event.target.querySelector('input[type=submit]').disabled = true
    const errors = this.verifyAllRadioButtons()

    if (errors.length > 0) {
      this.displayErrors(errors)
      event.target.querySelector('input[type=submit]').disabled = false
    } else {
      Turbo.navigator.submitForm(event.target)
    }
  }

  verifyAllRadioButtons() {
    const radioInputNames = Array.from(document.querySelectorAll('input[type=radio]')).map(input => input.name)
    const uniqueInputNames = [...new Set(radioInputNames)]

    let errors = []

    const allValuesSelected = uniqueInputNames.every(name => document.querySelector('input[name="' + name + '"]:checked'))
    if (!allValuesSelected) {
      errors.push("Please ensure all values are selected")
    }

    return errors
  }

  displayErrors(errors) {
    const message = errors.join("<br>")
    document.getElementById('flash').flash.alert(message)
  }
}
