import { Controller } from "@hotwired/stimulus";
import { Chart } from 'chart.js'

export default class extends Controller {
  static values = { attendancePercentage: Number, campfireAttendance: Number }

  connect() {
    const canvas = document.getElementById("attendance-chart");
    const ctx = canvas.getContext("2d");

    const verticalLinePlugin = {
      id: 'verticalLine',
      afterDraw: function(chartInstance) {
        var xScale = chartInstance.scales["x"];
        let yScale = chartInstance.scales["y"];
        var line;
        var style;

        if (chartInstance.options.verticalLine) {
          line = chartInstance.options.verticalLine[0];
  
          style = line.style;

          let xValue
          if (line.x) {
            xValue = xScale.getPixelForValue(line.x);
          } else {
            xValue = 0;
          }
  
          ctx.lineWidth = 5;
          if (xValue) {
            ctx.beginPath();
            ctx.setLineDash([4, 7])
            ctx.lineCap = "round"
            ctx.moveTo(xValue, 10);
            ctx.lineTo(xValue, yScale.height - 9);
            ctx.strokeStyle = style;
            ctx.stroke();
          }
        }
      }
    };
    
    var data = {
      labels: [''],
      datasets: [{
        backgroundColor: "rgba(100,214,131,1)",
        data: [this.attendancePercentageValue],
        borderWidth: 0,
        borderRadius: 6
      }]
    };

    new Chart(ctx, {
      type: 'bar',
      data: data,
      plugins: [verticalLinePlugin],
      options: {
        scales: {
          x: {
            max: 100,
            display: false,
          },
          y: {
            grid: {
              color: 'rgba(248,245,252,1)',
              drawOnChartArea: true,
              lineWidth: 75,
              offset: false,
              drawTicks: false,
              borderWidth: 0
            },
            ticks: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        indexAxis: 'y',
        "verticalLine": [{
          "x": this.campfireAttendanceValue,
          "style": "rgba(0,0,0,0.5)",
          "text": this.attendancePercentageValue + "%",
          "fillStyle": "black"
        }],
      }
    });
  }
}
