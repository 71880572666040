import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "checkmark", "checkbox" ]

  connect() {}

  toggleIcon() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.checkmarkTarget.classList.remove(this.checkboxTarget.checked ? 'checkmark-x' : 'checkmark-check')
    this.checkmarkTarget.classList.add(!this.checkboxTarget.checked ? 'checkmark-x' : 'checkmark-check')
  }
}
