import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "click", "anchor" ]

  click() {
    this.clickTargets.forEach(target => target.click())
  }

  encode({ target: { name, value } }) {
    for (const anchor of this.clickTargets) {
      anchor.search = new URLSearchParams({ [name]: value })
    }
  }
}
