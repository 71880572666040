import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "inputs", "display" ]

  connect() {
  }

  toggleForm(){
    this.inputsTarget.classList.toggle("hidden")
    this.displayTarget.classList.toggle("hidden")
  }
}
