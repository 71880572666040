import { Controller } from "@hotwired/stimulus"
import { Sortable } from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = {
    resourceName: String,
    animation: Number,
    handle: String
  }

  initialize() {
    this.end = this.end.bind(this)
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      ...this.defaultOptions,
      ...this.options
    })

  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  end({ item, newIndex, oldIndex }) {
    if (!this.sortable.el.dataset.url || !window._rails_loaded) return

    const lesson_id = item.dataset.id;
    const data = new FormData()
    data.append("new_position", newIndex + 1)
    data.append("lesson_id", lesson_id)

    Rails.ajax({
      url: this.sortable.el.dataset.url,
      type: 'PATCH',
      data,
      error: (resp, errorText, xhr) => {
        alert(errorText)
      }
    })
  }

  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      onEnd: this.end,
    }
  }

  get defaultOptions() {
    return {}
  }
}