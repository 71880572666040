import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  static targets = [ "source", "display" ]

  connect() {
    this.display = document.getElementById("links").getElementsByTagName("a")[0]
  }

  pastedLink(event){
    let paste = (event.clipboardData || window.clipboardData).getData('text');
    this.display.classList.remove("hidden")
    this.display.getElementsByTagName("div")[0].innerHTML = paste
    this.display.href = paste
    
    this.linkSharedEvent = new CustomEvent('linkShared', {
      detail: {
        href: paste
      }
    })

    window.dispatchEvent(this.linkSharedEvent);

    // TODO send shared link to the server so people joining after it's been shared can see it too

  }

  clearLink(event){
    this.sourceTarget.value = ""
    this.linkRemovedEvent = new CustomEvent('linkRemoved')
    window.dispatchEvent(this.linkRemovedEvent);
    this.display.classList.add("hidden")
    this.display.getElementsByTagName("div")[0].innerHTML = ""
    this.display.href = ""
  }

}
