import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ["checkmark", "checkbox", "checkmarkText", "filterForm", "select", "javascriptSelect", "filtersContainer", "option", "today", "displayedDate", "date"]

  toggleIcon() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.checkmarkTarget.classList.remove(this.checkboxTarget.checked ? 'checkmark-check' : 'checkmark-x')
    this.checkmarkTarget.classList.add(!this.checkboxTarget.checked ? 'checkmark-check' : 'checkmark-x')

    let textElement = this.checkmarkTextTarget
    if (textElement) {
      if (this.checkboxTarget.checked) {
        textElement.innerText = 'Remove'
        textElement.classList.remove('color-purple')
        textElement.classList.add('color-red')
      } else {
        textElement.innerText = 'Assign Me'
        textElement.classList.remove('color-red')
        textElement.classList.add('color-purple')
      }
    }
  }

  submitAssignForm() {
    Turbo.navigator.submitForm(this.checkboxTarget.closest('form'))
  }

  submitAssignFormForSelect() {
    Turbo.navigator.submitForm(this.selectTarget.closest('form'))
  }

  search() {
    Turbo.navigator.submitForm(this.filterFormTarget)
  }

  // these next three functions are all for the dropdown filters
  clearFilters(select) {
    let selectedOption = select.selectedIndex
    select.options[selectedOption].removeAttribute('selected', 'selected')
  }

  addFilter(event) {
    // grab target and get the proper filter ID based off the target
    let elementId = event.target.id.replace("-option", "");
    let filterElement = document.getElementById(elementId);
    let form = document.getElementById("filters-container").querySelector('form');

    if (elementId == 'clearfilters') {
      this.clearAllFilters()
    }
    else {
      // remove it from my 'filter-storage'
      filterElement.remove();
      filterElement.classList.remove("not-added-filter");

      // add it to the filters container
      form.appendChild(filterElement);

      // remove the option from the dropdown
      event.target.classList.add("added-filter-option");
    }
    Turbo.navigator.submitForm(form)
  }

  removeFilter(event) {
    // grab elements
    let filterElement = event.target.parentElement;
    let filtersStorage = document.getElementById("filters-storage");
    let dropdownElement = document.getElementById(`${filterElement.id}-option`);

    // remove the filter from container and add option back to dropdown
    filterElement.remove();
    filterElement.classList.add("not-added-filter")
    dropdownElement.classList.remove("added-filter-option");

    // add element back to storage
    filtersStorage.innerHTML += filterElement.outerHTML;
    this.search()
  }

  clearAllFilters() {
    // grab elements
    let container = document.getElementById("filters-container")
    let selectsArr = container.querySelectorAll("select");
    let filtersArr = container.querySelectorAll(".filter")
    let filtersStorage = document.getElementById("filters-storage");

    // clear the filters
    selectsArr.forEach(this.clearFilters);

    filtersArr.forEach((filter) => {
      if (!filter.classList.contains("not-added-filter")) {
        // grab dropdown element
        let dropdownElement = document.getElementById(`${filter.id}-option`);

        // remove the filter from container and add option back to dropdown
        filter.remove();
        dropdownElement.classList.remove("added-filter-option");

        // add element back to storage
        filtersStorage.innerHTML += filter.outerHTML;
      }
      else {
        return;
      }
    })
    this.search()
  }

  updateDate(date) {
    date.innerText = this.dateTarget.innerText;
  }
}
