import { Application } from "@hotwired/stimulus"
import { Autocomplete } from 'stimulus-autocomplete'

const application = Application.start()
class CustomAutocomplete extends Autocomplete {
  buildURL(query) {
    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append("search", query)
    params.append("search_source", "autocomplete")
    url.search = params.toString()

    return url.toString()
  }
}
application.register('autocomplete', CustomAutocomplete)

// Configure Stimulus development experience
application.warnings = true
application.debug = true
window.Stimulus = application

export { application }
